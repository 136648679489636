import { ViewIcon } from './view-icon.model';

export enum SceneViewIcon {
    TwoD = 'TWO_D',
    ThreeD = 'THREE_D',
    Parallel = 'PARALLEL',
    ViewWindow = 'VIEW_WINDOW',
    TwoDNChart = 'TWO_D_AND_CHART',
    ThreeDNaturalModes = 'THREE_D_NATURAL_MODES',
}

export const ViewIcons = [ViewIcon.Left, ViewIcon.Front, ViewIcon.Top, ViewIcon.Right, ViewIcon.Back, ViewIcon.Bottom];

export const SceneViews = [
    {
        title: 'BEARINX.EDITOR.SWITCH_VIEW.2D_VIEW',
        icon: SceneViewIcon.TwoD,
    },
    {
        title: 'BEARINX.EDITOR.SWITCH_VIEW.3D_VIEW',
        icon: SceneViewIcon.ThreeD,
    },
    {
        title: 'BEARINX.EDITOR.SWITCH_VIEW.PARALLEL',
        icon: SceneViewIcon.Parallel,
    },
    {
        title: 'BEARINX.EDITOR.SWITCH_VIEW.SCENE_VIEW',
        icon: SceneViewIcon.TwoDNChart,
    },
    {
        title: 'BEARINX.EDITOR.SWITCH_VIEW.3D_VIEW_NATURAL_MODES',
        icon: SceneViewIcon.ThreeDNaturalModes,
    },
];
