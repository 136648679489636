import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectModule } from '../../app/project/project.module';
import { FormControlModule } from '../form-control/form-control.module';
import { BearinxModalModule } from '../modal/modal.module';
import { PromptModule } from '../prompt/prompt.module';
import { TitleBarModule } from '../title-bar/title-bar.module';
import { UtilModule } from '../util/util.module';
import { ModelCopyComponent } from './copy/model-copy.component';
import { ModelDeleteComponent } from './delete/model-delete.component';
import { BaseExporter } from './export/base.exporter';
import { DATA_PROVIDERS } from './export/data.provider';
import { ModelExportComponent } from './export/model-export.component';
import { Vg2ModelExporter } from './export/vg2.model.exporter';
import { Vg2JsonModelExporter } from './export/vg2json.model.exporter';
import { Vg2Json2Vg2Dataprovider } from './export/vg2json2vg2.dataprovider';
import { Vg2Json2Vg2JsonDataprovider } from './export/vg2json2vg2json.dataprovider';
import { ModelMetadataFormComponent } from './metadata-form/model-metadata-form.component';
import { MetadataEditorComponent } from './metadata/metadata-editor.component';
import { ModelMismatchPrompt } from './model-mismatch/model-mismatch.prompt';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { GltfTextModelExporter } from './export/gltfText.model.exporter';
import { GltfTextDataprovider } from './export/gltfText.dataprovider';
import { BearinxGltfTextExporterService, BearinxMediatorModule, View3DModule, ViewsFoundationModule } from '@caeonline/viewer';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        TitleBarModule,
        ProjectModule,
        FormControlModule,
        BearinxModalModule,
        UtilModule,
        PromptModule,
        BreadcrumbsModule,
        View3DModule,
        ViewsFoundationModule,
        BearinxMediatorModule,
    ],
    declarations: [
        MetadataEditorComponent,
        ModelCopyComponent,
        ModelDeleteComponent,
        ModelExportComponent,
        ModelMetadataFormComponent,
        ModelMismatchPrompt,
    ],
    providers: [DatePipe],
    exports: [ModelMetadataFormComponent],
})
export class ModelModule {
    public static forRoot(): ModuleWithProviders<ModelModule> {
        return {
            ngModule: ModelModule,
            providers: [
                { provide: BaseExporter, multi: true, useClass: Vg2ModelExporter },
                { provide: BaseExporter, multi: true, useClass: Vg2JsonModelExporter },
                { provide: BaseExporter, multi: true, useClass: GltfTextModelExporter },
                { provide: DATA_PROVIDERS, multi: true, useClass: Vg2Json2Vg2Dataprovider },
                { provide: DATA_PROVIDERS, multi: true, useClass: Vg2Json2Vg2JsonDataprovider },
                { provide: DATA_PROVIDERS, multi: true, useClass: GltfTextDataprovider },
                BearinxGltfTextExporterService,
            ],
        };
    }
}
