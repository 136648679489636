import { Component, Input } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, map, mapTo, startWith } from 'rxjs/operators';
import { DrawerComponent } from '../../../modules/drawer/drawer.component';
import { NotificationsService } from '../../../modules/notification/notifications.service';
import { Roles } from '../../../modules/role/role.model';
import { RoleService } from '../../../modules/role/role.service';
import { SearchComponent } from '../../../modules/search/search.component';
import { AppBarService } from './app-bar.service';
import { TenantService } from 'src/modules/tenant/tenant.service';
import { DemoInfoComponent } from 'src/app/demo-info/demo-info.component';
import { ModalService } from 'src/modules/modal/services/modal.service';
import { BreadcrumbsService } from 'src/modules/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from 'src/modules/breadcrumbs/breadcrumb.model';

@Component({
    selector: 'bx-app-bar',
    templateUrl: 'app-bar.component.html',
    styleUrls: ['app-bar.component.scss'],
})
export class AppBarComponent {
    @Input() public navigationDrawer: DrawerComponent;
    @Input() public search: SearchComponent;

    public readonly ringring$: Observable<boolean>;
    public readonly subCaption$: Observable<string>;
    public readonly lockingVisible$: Observable<boolean>;
    public readonly lockingTooltipTranslationKey$: Observable<string>;
    public readonly dashboardDisabled$: Observable<boolean>;
    public readonly objectTreeDisabled$: Observable<boolean>;
    public readonly demoMode$: Observable<boolean>;

    protected breadcrumbs$: Observable<Breadcrumb[]>;

    constructor(
        public readonly appBarService: AppBarService,
        private readonly _notificationService: NotificationsService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _roleService: RoleService,
        private readonly _tenantService: TenantService,
        private readonly _modalService: ModalService,
        private readonly _breadCrumbsService: BreadcrumbsService,
    ) {
        const navigationEnd$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd));
        const activatedRoute$ = navigationEnd$.pipe(
            startWith(undefined),
            map(() => this._route.snapshot),
            map((route: ActivatedRouteSnapshot) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
        );

        // TODO: Extract ringring button to NotificationModule
        const hasUnseenNotifications$ = this._notificationService.notifications$.pipe(
            map(notifications => !!notifications.filter(notification => !notification.seen).length),
        );
        this.ringring$ = merge(hasUnseenNotifications$, navigationEnd$.pipe(mapTo(false)));

        this.subCaption$ = activatedRoute$.pipe(map(({ data: { subCaption } }) => subCaption ?? ''));

        this.lockingVisible$ = combineLatest([this._roleService.userHasRoles([Roles.ModelWrite]), activatedRoute$]).pipe(
            map(([hasPermission, route]) => route.data.showLocking),
        );

        this.lockingTooltipTranslationKey$ = this.appBarService.readOnlyActive$.pipe(
            map(readOnly => (readOnly ? 'PROMPTS.LOCKING.LOCK_CLOSED' : 'PROMPTS.LOCKING.LOCK_OPEN')),
        );

        this.dashboardDisabled$ = combineLatest([this._roleService.userHasRoles([Roles.ModelCalculate]), activatedRoute$]).pipe(
            map(([hasPermission, route]) => !(hasPermission && route.data.enableDashboard)),
        );

        this.objectTreeDisabled$ = activatedRoute$.pipe(map(({ data: { enableTree } }) => !enableTree));

        this.demoMode$ = this._tenantService.tenant$.pipe(map(tenant => tenant?.name === 'Demo'));

        this.breadcrumbs$ = combineLatest([this._breadCrumbsService.breadcrumbs, activatedRoute$]).pipe(
            map(([breadcrumbs, route]) => (route?.routeConfig?.path === 'import' ? breadcrumbs : [])),
        );
    }

    public openSchaefflerPage() {
        window.open('https://www.schaeffler.com', '_blank');
    }

    public showDemoInfo() {
        this._modalService.showContentDialog(DemoInfoComponent, 'BEARINX.HOME.ASIDE.MENU.CONTENT.DEMO_INFO_PAGE.TITLE1');
    }
}
