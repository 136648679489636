import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalErrorHandler } from './global-error-handler/global-error.handler';
import { PromptModule } from '../prompt/prompt.module';
import { ToastModule } from '../toast/toast.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { ConnectionErrorPrompt } from './connection-error/connection-error.prompt';
import { ErrorSupportPrompt } from './error-support-prompt/error-support.prompt';
import { ErrorRetryPrompt } from './error-retry-prompt/error-retry.prompt';

@NgModule({
    imports: [TranslateModule, PromptModule, ToastModule, RouterModule, CommonModule],
    declarations: [NotFoundComponent, ServerErrorComponent, ConnectionErrorPrompt, ErrorSupportPrompt, ErrorRetryPrompt],
    exports: [NotFoundComponent, ServerErrorComponent],
})
export class ErrorHandlingModule {
    public static forRoot(): ModuleWithProviders<ErrorHandlingModule> {
        return {
            ngModule: ErrorHandlingModule,
            providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
        };
    }
}
