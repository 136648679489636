import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataModelInterceptor } from 'src/modules/data-model/data-model.interceptor';
import { HttpErrorInterceptor } from 'src/modules/error-handling';
import { GroupInterceptor } from 'src/modules/group/group.interceptor';
import { LockTokenInterceptor } from 'src/modules/locking/lock-token.interceptor';
import { ModelHashInterceptor } from 'src/modules/model/model-hash.interceptor';
import { SpinnerInterceptor } from 'src/modules/spinner/spinner.interceptor';
import { TenantInterceptor } from 'src/modules/tenant/tenant.interceptor';
import { ThumbnailInterceptor } from 'src/modules/thumbnail/thumbnail.interceptor';

@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ThumbnailInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DataModelInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LockTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ModelHashInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: GroupInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ],
})
export class InterceptorModule {}
