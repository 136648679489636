import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, NEVER, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, finalize, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { AppBarService } from '../../app/bearinx/app-container/app-bar.service';
import { EditorContext } from '../../app/editor/editor-context.model';
import { ErrorMessagesService, ErrorsHandledInInterceptor, HttpStatusCode, LoggingService } from '../error-handling';
import { PromptResult } from '../prompt/prompt.model';
import { PromptService } from '../prompt/prompt.service';
import { WindowRef } from '../util/window-ref';
import { DocumentType } from './document-type.model';
import { LockTokenApiService } from './lock-token-api.service';
import { LockTokenRefreshEvent, LockTokenRefreshService } from './lock-token-refresh.service';
import { LockTokenRevocationService } from './lock-token-revocation.service';
import { LockTokenStorageService } from './lock-token-storage.service';
import { LockToken } from './lock-token.model';
import { ModelLockedPrompt } from './prompts/model-locked/model-locked.prompt';
import { OverrideLockPrompt } from './prompts/override-lock/override-lock.prompt';
import { ReleaseLockPrompt } from './prompts/release-lock/release-lock.prompt';
import { TokenExpiredPrompt } from './prompts/token-expired/token-expired.prompt';
import { TokenOverriddenPrompt } from './prompts/token-overridden/token-overridden.prompt';
import { GroupService } from '../group/group.service';
import { Roles } from '../role/role.model';

@Injectable()
export class LockingService implements OnDestroy {
    private _subscription?: Subscription;
    private _modelId: string;
    private _tokenChange = new BehaviorSubject<LockToken | null>(null);
    private groupIsModelWrite: boolean;

    constructor(
        private readonly _appBarService: AppBarService,
        private readonly _editorContext: EditorContext,
        private readonly _promptService: PromptService,
        private readonly _lockTokenApiService: LockTokenApiService,
        private readonly _lockTokenStorageService: LockTokenStorageService,
        private readonly _lockTokenRefreshService: LockTokenRefreshService,
        private readonly _lockTokenRevocationService: LockTokenRevocationService,
        private readonly _errorMessagesService: ErrorMessagesService,
        private readonly _windowRef: WindowRef,
        private readonly _logger: LoggingService,
        private readonly _groupService: GroupService,
    ) {}

    public init(modelId: string): void {
        this._subscription?.unsubscribe();

        if (!modelId) {
            throw new Error('Model ID not set.');
        }

        this.groupIsModelWrite = this._groupService.groups[0].roles.some(role => role === Roles.ModelWrite);

        const watchAppBar$ = this._appBarService.toggleReadOnly$.pipe(
            map(() => this._tokenChange.value),
            exhaustMap(token => {
                const message = token ? 'with token being present. Try to check in.' : 'without token being present. Try to check out.';
                this._logger.log(`[LockingService] App bar toggled ${message}`);
                return (token ? this.checkIn().pipe(filter(result => !result.cancelled)) : this.checkOut()).pipe(
                    catchError(err => {
                        if (!ErrorsHandledInInterceptor.includes(err.status)) {
                            this._errorMessagesService.displayErrorMessage(err.rejection ?? err.message ?? err.toString());
                        }
                        return of();
                    }),
                );
            }),
        );
        const handleOverriddenToken$ = this._lockTokenRevocationService.revoked$.pipe(
            switchMap(() => {
                this._logger.log('[LockingService] Token revoked.');
                this._tokenChange.next(null);
                return this._handleTokenOverridden();
            }),
        );
        const refreshToken$ = this._tokenChange.pipe(
            switchMap(token => this._lockTokenRefreshService.refreshBeforeExpiry(token)),
            switchMap(event => {
                if (event instanceof LockTokenRefreshEvent) {
                    this._logger.log('[LockingService] Token refreshed.');
                    this._tokenChange.next(event.lockToken);
                    return NEVER;
                } else {
                    this._logger.log('[LockingService] Token expired.');
                    this._tokenChange.next(null);
                    return this._handleTokenExpiry();
                }
            }),
        );
        const updateToken$ = this._tokenChange.pipe(
            tap(token => {
                this._logger.log('[LockingService] Token updated.');
                this._lockTokenStorageService.set(modelId, token);
                this._editorContext.readOnly = !token;
                this._appBarService.toggleReadOnlyActive(!token);
            }),
        );

        this._modelId = modelId;

        const lockToken = this._lockTokenStorageService.get(modelId);
        this._tokenChange.next(this._lockTokenValid(lockToken) ? lockToken : null);
        this._subscription = merge(watchAppBar$, handleOverriddenToken$, refreshToken$, updateToken$).subscribe();

        this._logger.log(`[LockingService] Initialized for model ID ${modelId}.`);
    }

    private _lockTokenValid(lockToken: LockToken | null): boolean {
        return (
            !!lockToken &&
            lockToken.documentId === this._modelId &&
            lockToken.documentType === DocumentType.Model &&
            new Date(lockToken.expireAt) > new Date()
        );
    }

    public checkOut(): Observable<any> {
        return this._lockTokenApiService.create(DocumentType.Model, this._modelId).pipe(
            tap(token => this._tokenChange.next(token)),
            catchError(err => this._handleCheckOutError(err)),
        );
    }

    private _handleCheckOutError(error: HttpErrorResponse): Observable<null> {
        if (error.status === HttpStatusCode.Locked) {
            return this._handleModelAlreadyCheckedOutError(error.error);
        }
        return throwError(error);
    }

    private _handleModelAlreadyCheckedOutError(existingToken: LockToken): Observable<null> {
        return this._promptService.displayPrompt(ModelLockedPrompt, false, existingToken).pipe(
            switchMap(result => {
                if (result === PromptResult.Cancel) {
                    return of(null);
                }

                return this._promptService.displayPrompt(OverrideLockPrompt, false, existingToken);
            }),
            switchMap(result => {
                if (result !== PromptResult.Confirm) {
                    return of(null);
                }

                return this._lockTokenApiService.delete(DocumentType.Model, this._modelId, true).pipe(mapTo(null));
            }),
        );
    }

    private _handleTokenExpiry(): Observable<PromptResult> {
        return this._promptService.displayPrompt(TokenExpiredPrompt);
    }

    private _handleTokenOverridden(): Observable<PromptResult> {
        return this._promptService
            .displayPrompt(TokenOverriddenPrompt, true)
            .pipe(tap(() => this._windowRef.nativeWindow.location.reload()));
    }

    // prettier-ignore
    public checkIn(): Observable<{ cancelled: boolean }> {
        if (!this._tokenChange.value) {
            throw new Error('Model is not checked out.');
        }

        return this._promptService.displayPrompt(ReleaseLockPrompt).pipe(
            switchMap(result =>
                result !== PromptResult.Confirm
                    ? of({ cancelled: true })
                    : this._lockTokenApiService.delete(DocumentType.Model, this._modelId).pipe(
                        finalize(() => this._tokenChange.next(null)),
                        mapTo({ cancelled: false }),
                    ),
            ),
        );
    }

    public get isCheckedOut(): boolean {
        if (!this.groupIsModelWrite) {
            this._tokenChange.next(null);
        }

        return !!this._tokenChange.value;
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }
}
