import { NgModule } from '@angular/core';
import { FormControlModule } from '../form-control/form-control.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleBarModule } from '../title-bar/title-bar.module';
import { TenantSelectorComponent } from './tenant-selector/tenant-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from '../auth/auth.module';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, TitleBarModule, FormControlModule, AuthModule, TranslateModule],
    declarations: [TenantSelectorComponent],
    exports: [TenantSelectorComponent],
})
export class TenantModule {}
