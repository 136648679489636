import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { auditTime, delay, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Project } from '../../app/project/project.model';
import { ProjectService } from '../../app/project/project.service';
import { Group } from '../group/group.model';
import { GroupService } from '../group/group.service';
import { loadAppConfig, sortByName } from '../util/util';
import { BookmarkItem, BookmarkType } from './bookmark-item.model';

@Injectable({ providedIn: 'root' })
export class BookmarkService {
    public appConfig = loadAppConfig();
    private readonly _baseUrl = `${this.appConfig.baseUrl}bookmarks/`;
    private readonly _bookmarksChanged$ = new BehaviorSubject<boolean>(false);

    readonly bookmarks$: Observable<BookmarkItem[] | []> = combineLatest([
        this._groupService.group$,
        this._projectService.projects$,
        this._bookmarksChanged$,
    ]).pipe(
        auditTime(300),
        filter((bundle): bundle is [Group, Project[], boolean] => !!bundle[0]),
        map(([selectedGroup, projects]) => projects.filter(p => p.groupId === selectedGroup.id)),
        switchMap((projects: Project[]) =>
            this.get('Project').pipe(
                map((bookmarks: BookmarkItem[]) =>
                    bookmarks ? bookmarks.filter(b => projects.filter(p => p.name === b.name).length > 0) : [],
                ),
            ),
        ),
        map(bookmarks => bookmarks.sort(sortByName)),
        shareReplay(1),
    );

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _projectService: ProjectService,
        private readonly _groupService: GroupService,
    ) {}

    public add(type: BookmarkType, objectId: string): Observable<void> {
        return this._httpClient.post<void>(`${this._baseUrl}${type}/${objectId}`, null).pipe(tap(() => this._bookmarksChanged$.next(true)));
    }

    public remove(type: BookmarkType, objectId: string): Observable<void> {
        return this._httpClient.delete<void>(`${this._baseUrl}${type}/${objectId}`).pipe(tap(() => this._bookmarksChanged$.next(true)));
    }

    public get(type: BookmarkType): Observable<BookmarkItem[]> {
        return this._httpClient.get<BookmarkItem[]>(`${this._baseUrl}${type}`);
    }
}
