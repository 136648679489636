import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Project } from '../project.model';
import { ErrorMessagesService, ErrorsHandledInInterceptor, HttpStatusCode } from '../../../modules/error-handling';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'bx-project-rename',
    templateUrl: './project-rename.component.html',
    styleUrls: ['./project-rename.component.scss'],
})
export class ProjectRenameComponent implements OnInit, OnDestroy {
    public projectCtrl = new FormControl();
    public disabled = true;

    public project: Project;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _projectService: ProjectService,
        private readonly _router: Router,
        private readonly _location: Location,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _errorService: ErrorMessagesService,
        private readonly _translateService: TranslateService,
    ) {}

    public ngOnInit() {
        const id = this._activatedRoute.snapshot.paramMap.get('id');
        if (id != null) {
            this._projectService
                .get(id)
                .pipe(takeUntil(this._destroy$))
                .subscribe(project => {
                    this.project = project;
                    this.projectCtrl.patchValue(project);
                });
        }
        this.projectCtrl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value: any) => (this.disabled = !value?.name || value.name.trim().length <= 0));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onConfirm(): void {
        const project = this.projectCtrl.value;
        const id = this._activatedRoute.snapshot.paramMap.get('id');
        if (id != null) {
            this._projectService
                .update(id, project)
                .pipe(takeUntil(this._destroy$))
                .subscribe(
                    _ => this._router.navigate(['models', 'library']),
                    (error: HttpErrorResponse) => {
                        if (error.status === HttpStatusCode.Conflict) {
                            const errorMessage = this._translateService.instant('SHARED.PROJECT.NAME.ERROR_MESSAGE_DUPLICATE');
                            this._errorService.displayErrorMessage(errorMessage);
                        } else {
                            if (!ErrorsHandledInInterceptor.includes(error.status)) {
                                this._errorService.displayErrorMessage(error.error);
                            }
                            this._router.navigate(['models', 'library']);
                        }
                    },
                );
        }
    }

    public onCancel(): void {
        this._location.back();
    }
}
