import { NgModule } from '@angular/core';
import { FallbackImageDirective } from './fallback-image.directive';
import { ThumbnailDirective } from './thumbnail.directive';

const DECLARATIONS = [FallbackImageDirective, ThumbnailDirective];

@NgModule({
    declarations: [...DECLARATIONS],
    exports: [...DECLARATIONS],
})
export class ThumbnailModule {}
