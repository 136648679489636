import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval as observableInterval, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, pluck, tap } from 'rxjs/operators';
import { LogItem } from '../../app/editor/dialogs/dashboard/logbook/log-item.model';
import { GROUP_HEADER } from '../group/group.interceptor';
import { UrlParameterEncodingCodec } from '../http/url.parameter.encoding';
import { TENANT_HEADER } from '../tenant/tenant.interceptor';
import { ModelErrorHandlerService } from './errors/model-error-handler.service';
import { ModelMetadata } from './metadata-form/model-metadata.model';
import { ApiModelObject, ModelCreatedResponse, ModelObject } from './model-object.model';
import { ModelTransformer } from './model-transformer.service';
import { Model, ModelBase } from './model.model';
import { Property } from './property.model';
import { Messages } from './messages.model';
import { loadAppConfig } from '../util/util';
import { ReportLayoutType } from '../../app/editor/dialogs/dashboard/report/report-layout-type.enum';

interface ModelLocation {
    tenantId: string;
    groupId: string;
    projectId?: string;
}

// TODO models update when importing/creating/deleting
@Injectable({
    providedIn: 'root',
})
export class ModelApiService {
    public appConfig = loadAppConfig();

    private readonly _baseUrl = `${this.appConfig.baseUrl}${this.appConfig.apiVersion}models/`;

    private readonly _logPollingInterval: number;
    private readonly _modelUpdated$ = new Subject<void>();

    readonly modelUpdated$ = this._modelUpdated$.asObservable();

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _transformer: ModelTransformer,
        private readonly _errorHandler: ModelErrorHandlerService,
    ) {
        this._logPollingInterval = this.appConfig.calculation.logPollingInterval;
    }

    public getExportToken(id: string): Observable<string> {
        return this._httpClient.post<string>(this._getModelUrl(id, 'export'), null);
    }

    public export(id: string, exportFormat: string, token: any): Observable<any> {
        let params = new HttpParams({ encoder: new UrlParameterEncodingCodec() });
        let options;
        if (token?.hasOwnProperty('token')) {
            params = params.set('token', token.token);
        }
        if (exportFormat && exportFormat.toLocaleLowerCase() === 'vg2') {
            options = { params: params, responseType: 'blob' as 'json' };
        } else {
            options = { params: params };
        }
        return this._httpClient.get(this._getModelUrl(id, 'export', exportFormat), options);
    }

    public import(importModel: FormData): Observable<Model<ModelObject>> {
        const url = `${this._baseUrl}import`;
        return this._httpClient.post<Model<ModelObject>>(url, importModel);
    }

    public get(id: string): Observable<Model<ModelObject>> {
        const headers = new HttpHeaders().set('x-handle-403-internally', 'true');
        return this._httpClient.get<Model<ApiModelObject>>(this._getModelUrl(id), { headers }).pipe(
            catchError((error) => this._errorHandler.handleError(error)),
            map((model) => this._transformer.api2local(model)),
        );
    }

    public getAll(tenantId?: string, groupId?: string): Observable<ModelBase[]> {
        const headers = {
            ...(tenantId ? { [TENANT_HEADER]: tenantId } : {}),
            ...(groupId ? { [GROUP_HEADER]: groupId } : {}),
        };
        return this._httpClient.get<ModelBase[]>(`${this._baseUrl}`, { headers }).pipe(catchError(() => of([])));
    }

    public getRecent(): Observable<ModelBase[]> {
        return this._httpClient.get<ModelBase[]>(`${this._baseUrl}recent/`).pipe(catchError(() => of([])));
    }

    public getInfoWarningMessage(): Observable<Messages> {
        const messagesUrl = this.appConfig.tutorials.messages;

        return this._httpClient.get<any>(messagesUrl).pipe(catchError(() => of([])));
    }

    public create(createModelRequest: Model<ApiModelObject>): Observable<ModelCreatedResponse> {
        return this._httpClient.post<ModelCreatedResponse>(`${this._baseUrl}`, createModelRequest);
    }

    // prettier-ignore
    public copy(id: string, name: string, sourceLocation: ModelLocation, targetLocation: ModelLocation): Observable<ModelCreatedResponse> {
        return this._httpClient.post<ModelCreatedResponse>(
            `${this._baseUrl}copy`,
            {
                tenantId: sourceLocation.tenantId,
                groupId: sourceLocation.groupId,
                id,
                name,
                projectId: targetLocation.projectId,
            },
            {
                headers: { [TENANT_HEADER]: targetLocation.tenantId, [GROUP_HEADER]: targetLocation.groupId },
            },
        ).pipe(tap(() => this._modelUpdated$.next()));
    }

    // TODO will model parameterization be sent to server?
    //      if so we can have the update endpoint return the full model
    public update(id: string, metadata: ModelMetadata): Observable<void> {
        return this._httpClient.put<void>(this._getModelUrl(id), metadata).pipe(tap(() => this._modelUpdated$.next()));
    }

    public copyObject(
        targetModelId: string,
        targetObjectId: string,
        sourceModelId: string,
        sourceObjectId: string,
        currentShaftId: string | undefined,
        deleteFromSourceParent: boolean,
    ): Observable<ModelObject> {
        const data = {
            targetObjectId,
            sourceModelId,
            sourceObjectId,
            currentShaftId,
            deepCopy: true,
            deleteFromSourceParent,
        };
        return this._httpClient
            .post<ApiModelObject>(this._getModelUrl(targetModelId, 'copyobject'), data)
            .pipe(map((apiObject) => this._transformer.api2localObject(apiObject)));
    }

    public insertObject(id: string, parentId: string, childObject: ApiModelObject | ModelObject): Observable<ModelObject> {
        const child = 'id' in childObject ? this._transformer.local2apiObject(childObject) : childObject;
        return this._httpClient.post<ApiModelObject>(this._getModelUrl(id, 'insertObject'), { parentId, child }).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public insertBearing(id: string, parentId: string, childObject: ApiModelObject | ModelObject): Observable<ModelObject> {
        const child = 'id' in childObject ? this._transformer.local2apiObject(childObject) : childObject;
        return this._httpClient.post<ApiModelObject>(this._getModelUrl(id, 'insertBearing'), { parentId, child }).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public setProperties(id: string, properties: Property[]): Observable<ModelObject> {
        return this._httpClient.put<ApiModelObject>(this._getModelUrl(id, 'setproperties'), properties).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public getSimilarObjects(modelId: string, objectId: string): Observable<ModelObject[]> {
        return this._httpClient.get<ModelObject[]>(this._getModelUrl(modelId, 'similar', objectId));
    }

    public sortObjects(modelId: string, orderedChildren: string[], parentObjectId: string): Observable<ModelObject> {
        const body = {
            ParentObjectId: parentObjectId,
            OrderedChildren: orderedChildren,
        };
        return this._httpClient.put<ApiModelObject>(this._getModelUrl(modelId, 'permute'), body).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public setAssembly(
        id: string,
        assemblyId: string,
        assemblyObjectId: string,
        assemblyObjectIds: string[],
        properties: Property[],
    ): Observable<ModelObject> {
        const body = {
            assemblyId,
            assemblyObjectId,
            assemblyObjectIds,
            updates: properties,
        };
        return this._httpClient.put<ApiModelObject>(this._getModelUrl(id, 'assembly'), body).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public setApproximation(id: string, properties: Property[]): Observable<ModelObject> {
        return this._httpClient.put<ApiModelObject>(this._getModelUrl(id, 'approximation'), properties[0]).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public delete(id: string): Observable<any> {
        return this._httpClient.delete<ApiModelObject>(this._getModelUrl(id));
    }

    public deleteTargetObject(id: string, targetObjectId: string): Observable<any> {
        return this._httpClient.delete<ApiModelObject>(this._getModelUrl(id, targetObjectId)).pipe(
            map((apiObject) => this._transformer.api2localObject(apiObject)),
            tap(() => this._modelUpdated$.next()),
        );
    }

    public calculate(id: string): Observable<string> {
        return this._httpClient.post<{ calculationId: string }>(this._getModelUrl(id, 'calculate'), null).pipe(pluck('calculationId'));
    }

    public getLogs(id: string, calculationId: string): Observable<Array<LogItem>> {
        return this._httpClient.get<Array<LogItem>>(this._getModelUrl(id, 'logs', calculationId));
    }

    public getLogStream(id: string, calculationId: string): Observable<Array<LogItem>> {
        return observableInterval(this._logPollingInterval).pipe(mergeMap(() => this.getLogs(id, calculationId)));
    }

    public getResult(id: string, calculationId: string): Observable<ModelObject> {
        const headers = new HttpHeaders().set('x-handle-403-internally', 'true');
        return this._httpClient.get<ApiModelObject>(this._getModelUrl(id, 'result', calculationId), { headers }).pipe(
            catchError((error) => this._errorHandler.handleError(error)),
            map((apiObject) => this._transformer.api2localObject(apiObject)),
        );
    }

    public getOutput(id: string, calculationId: string, outputType: string, layout: ReportLayoutType): Observable<Blob> {
        const modelUrl = this._getModelUrl(id, 'output', calculationId, outputType, layout);
        return this._httpClient.get(modelUrl, { responseType: 'blob' });
    }

    // prettier-ignore
    public createAssembly(
        id: string,
        parentObjectId: string,
        assemblyId: string,
        assemblyObjectIds: string[] = [],
    ): Observable<ModelObject> {
        return this._httpClient.post<ModelObject>(this._getModelUrl(id, 'assembly'), {
            parentObjectId,
            assemblyId,
            assemblyObjectIds,
        })
            .pipe(map(apiObject => this._transformer.api2localObject(apiObject)));
    }

    private _getModelUrl(id: string, ...params: string[]): string {
        const baseUrl = `${this._baseUrl}${encodeURIComponent(id)}`;

        return params && params.length > 0 ? baseUrl + '/' + params.map((p) => encodeURIComponent(p)).join('/') : baseUrl;
    }

    public setLoadCaseAssembly(modelId: string, parentId: string, child: { type: string }): Observable<ModelObject> {
        const url = `${this._baseUrl}${modelId}/loadcaseassembly`;

        return this._httpClient.post<ModelObject>(url, { parentId, child });
    }
}
