import { Injectable } from '@angular/core';
import { Breadcrumb } from './breadcrumb.model';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '../../app/project/project.model';
import { ProjectService } from '../../app/project/project.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    private _projects: Project[];

    private _breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([{ label: 'Home', projectId: undefined }]);

    constructor(private readonly translateService: TranslateService, private readonly _projectService: ProjectService) {
        this._projectService.projects$.pipe().subscribe(projects => {
            this._projects = projects;
        });
    }

    public get breadcrumbs(): Observable<Breadcrumb[]> {
        return this._breadcrumbs.asObservable();
    }

    private setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        this._breadcrumbs.next(breadcrumbs);
    }

    public updateBreadcrumbs(_breadcrumbs: Breadcrumb[], project: Project | undefined): Breadcrumb[] {
        if (project !== undefined && this._projects !== undefined) {
            // select project
            return this.calculateBreadcrumbs(_breadcrumbs, project);
        } else if (project === undefined) {
            // home
            this.resetBreadcrumbs(_breadcrumbs);
            return _breadcrumbs;
        } else if (project !== undefined && _breadcrumbs.length === 0) {
            // init for project selection
            return this.calculateBreadcrumbs(_breadcrumbs, project);
        }
        return _breadcrumbs;
    }

    private calculateBreadcrumbs(_breadcrumbs: Breadcrumb[], project: Project): Breadcrumb[] {
        const position = _breadcrumbs.findIndex(_breadcrumb => _breadcrumb.projectId === project.id);
        if (position !== -1) {
            _breadcrumbs.splice(position + 1);
            return _breadcrumbs;
        } else {
            this._calcBreadcrumbs(_breadcrumbs, project);
            this.resetBreadcrumbs(_breadcrumbs);
            _breadcrumbs = [..._breadcrumbs, ...this._calcBreadcrumbs([], project).reverse()];

            this.setBreadcrumbs(_breadcrumbs);

            return _breadcrumbs;
        }
    }

    private _calcBreadcrumbs(breadcrumbs: Breadcrumb[], project: Project): Breadcrumb[] {
        if (project === null || project === undefined) {
            this.resetBreadcrumbs(breadcrumbs);
        } else {
            breadcrumbs.push({ label: project.name, projectId: project.id });
            if (project.parentId !== undefined && project.parentId !== '' && this._projects !== null) {
                const parentProject = this._projects.find(_project => _project.id === project.parentId);
                if (parentProject !== undefined) {
                    return this._calcBreadcrumbs(breadcrumbs, parentProject);
                }
            }
        }

        return breadcrumbs;
    }

    private resetBreadcrumbs(_breadcrumbs: Breadcrumb[]): void {
        _breadcrumbs.length = 0;
        _breadcrumbs.push({
            label: this.translateService.instant('BREADCRUMBS.HOME'),
            projectId: undefined,
        });
    }
}
