<div class="bx__card image">
    <div class="bookmark-container overlay-element" *ngIf="bookmarkable">
        <!-- TODO: Improve visibility of stars on green-ish backgrounds -->
        <!-- TODO: Position of stars -->
        <bx-icon
            *ngIf="bookmarkable"
            [icon]="bookmarked ? 'star' : 'star_border'"
            [ngClass]="bookmarked ? 'bookmark' : 'no-bookmark'"
            (click)="setBookmarkState($event)"
            [title]="title | translate"
        >
        </bx-icon>
    </div>
    <figure (click)="onSelected()">
        <img *ngIf="image" [src]="image" bxFallbackImg="assets/default-thumbnail.png" />
        <figcaption class="preserve-whitespace" #captionRef (mouseenter)="setStyles(captionRef)" (mouseleave)="clearStyles(captionRef)">{{ caption }}</figcaption>
    </figure>
    <ng-container *ngIf="enableContextMenu && permission$ | async as permission">
        <i class="options material-icons overlay-element" [bxContextMenuClick]="contextMenu">more_vert</i>

        <ng-template #contextMenu>
            <bx-context-menu>
                <bx-context-menu-item
                    *ngIf="permission.allowUpdate"
                    icon="create"
                    [caption]="'GLOBALS.RENAME' | translate"
                    (action)="renameItem()"
                ></bx-context-menu-item>
                <bx-context-menu-item
                    *ngIf="permission.allowDelete"
                    icon="delete"
                    [caption]="'GLOBALS.DELETE' | translate"
                    (action)="deleteItem()"
                ></bx-context-menu-item>
            </bx-context-menu>
        </ng-template>
    </ng-container>
</div>
