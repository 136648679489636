import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { GroupSelectorComponent } from './group-selector/group-selector.component';
import { FormControlModule } from '../form-control/form-control.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GroupNamePipe } from './group-name.pipe';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, AuthModule, FormControlModule, TranslateModule],
    declarations: [GroupSelectorComponent, GroupNamePipe],
    exports: [GroupNamePipe, GroupSelectorComponent],
})
export class GroupModule {}
