import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorMessagesService } from '../error-handling';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ThumbnailInterceptor implements HttpInterceptor {
    public static readonly ERROR_FLAG_KEY = 'thumbnailErrorOccurred';

    constructor(private _errorMessagesService: ErrorMessagesService, private _translateService: TranslateService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse && error.status === 404 && request.headers.get('x-handle-thumbnail') === 'true') {
                    const wasErrorCaught = sessionStorage.getItem(ThumbnailInterceptor.ERROR_FLAG_KEY) === 'true';

                    if (!wasErrorCaught) {
                        sessionStorage.setItem(ThumbnailInterceptor.ERROR_FLAG_KEY, 'true');
                        const message = this._translateService.instant('ERRORS.THUMBNAIL_MISSING');
                        this._errorMessagesService.displayErrorMessage(message);
                    }
                    return throwError(error);
                }
                return throwError(error);
            }),
        );
    }
}
