import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CURRENT_TENANT_KEY, DEMO_TEMP_TENANT, Tenant } from '../tenant.model';
import { TenantService } from '../tenant.service';
import { WebStorageService } from 'src/modules/storage/web-storage.service';

@Component({
    selector: 'bx-tenant-selector',
    templateUrl: './tenant-selector.component.html',
    styleUrls: ['./tenant-selector.component.scss'],
})
export class TenantSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public disabled = false;
    @Output() public readonly tenantSelected = new EventEmitter<Tenant>();

    public readonly tenantCtrl = new FormControl();
    public readonly tenantItems$ = this._tenantService.tenants$.pipe(
        map((tenants) => tenants.map(({ id, name: caption }) => ({ id, caption }))),
    );

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _tenantService: TenantService,
        private readonly _webStorageService: WebStorageService,
    ) {}

    public ngOnInit() {
        this.tenantCtrl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                map((tenantId) => this._tenantService.getTenant(tenantId)),
            )
            .subscribe((tenant) => {
                this._setTenant(tenant);
            });

        this.setTenantOnload();
        this._tenantService.tenant$.pipe(takeUntil(this._destroy$)).subscribe((tenant) => {
            this.tenantCtrl.setValue(tenant?.id, { emitEvent: false });
        });
    }

    private _setTenant(tenant: Tenant | undefined) {
        this._tenantService.setTenant(tenant);
        this.tenantSelected.emit(tenant);
    }

    private setTenantOnload() {
        const tenantId = this._webStorageService.getValue(CURRENT_TENANT_KEY);
        if (tenantId !== null) {
            return;
        }

        this._tenantService?.tenants$.pipe(takeUntil(this._destroy$)).subscribe((tenants) => {
            const currentTenant = this._getRelevantTenant(tenants);

            this.tenantCtrl.setValue(currentTenant && currentTenant !== undefined ? currentTenant.id : null, { emitEvent: false });
            this._setTenant(currentTenant);
        });
    }
    private _getRelevantTenant(tenants: Tenant[]): Tenant | undefined {
        if (tenants.length === 0) {
            return undefined;
        }

        if (tenants.length === 1 && this._nonDemoTempTenant(tenants[0].name)) {
            return tenants[0];
        }

        const windowAny = window as any;
        if (windowAny.Cypress != null) {
            return tenants.filter(({ name }) => name === 'Schaeffler AG')[0];
        }
        return tenants.filter(({ name }) => !this._nonDemoTempTenant(name))[0];
    }

    private _nonDemoTempTenant(name: string): boolean {
        return DEMO_TEMP_TENANT.includes(name);
    }

    public ngOnChanges(): void {
        if (this.disabled) {
            this.tenantCtrl.disable();
        } else {
            this.tenantCtrl.enable();
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
