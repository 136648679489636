import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

export namespace AuthGuard {
    export const canActivate: CanActivateFn = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const authService = inject(AuthenticationService);
        return authService.hasValidAccessToken();
    };
}
